import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Box, P, theme } from "src/style"
import { InnerContainer } from "src/templates/project"

const Container = styled(Box).attrs({
  justifyContent: "center",
  alignItems: "center",
  pt: [6, 6],
  pb: [0, 6],
})`
  margin: auto;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: translateZ(0);
`

const Paragraph = styled(P).attrs({
  width: props =>
    props.type === "75% (ALIGN LEFT)" || props.type === "75% (ALIGN CENTER)"
      ? "100%"
      : "100%",
})`
  margin-left: auto;
  margin-right: auto;

  ${theme.mediaQueries.small} {
    width: 90%;
  }

  ${theme.mediaQueries.medium} {
    width: 80%;
  }
  
  margin-left: auto;
  margin-right: auto;

  ${props =>
    props.type === "75% (ALIGN CENTER)" &&
    `
    text-align: center;

    ${theme.mediaQueries.large} {
      width: 100%;
    }
  `}

  ${props =>
    props.type === "50% (ALIGN CENTER)" &&
    `
    text-align: center;
    width: 100%;

    ${theme.mediaQueries.small} {
      width: 75%;
    }

    ${theme.mediaQueries.large} {
      width: 50%;
    }
  `}

  ${props =>
    props.type === "50% (ALIGN LEFT)" &&
    `
    text-align: left;
    margin-left: 0;
    width: 100%;

    ${theme.mediaQueries.small} {
      width: 70%;
    }

    ${theme.mediaQueries.large} {
      width: 50%;
    }
  `}
`

const ProjectParagraph = ({
  data: {
    primary: { content, width },
  },
}) => {
  return (
    <InnerContainer>
      <Container maxWidth={["100%", "100%", "1100px", "1300px", "1500px"]}>
        <Paragraph type={width}>{content.text}</Paragraph>
      </Container>
    </InnerContainer>
  )
}

export default ProjectParagraph

export const paragraphQuery = graphql`
  fragment Paragraph on PrismicProjectBodyText {
    id
    primary {
      width
      content {
        html
        text
      }
    }
  }
`
