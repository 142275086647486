import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Box, Caption, P } from "src/style"
import Img from "src/components/Image"
import { InnerContainer } from "src/templates/project"

const Container = styled(Box).attrs({
  display: "flex",
  alignItems: "center",
  flexDirection: ["column", "row"],
  pt: 4,
  pb: [10, 10, "7em", "8em", "9em"],
})`
  margin: 0 auto;
  justify-content: center;
`

const ProjectTwoUpImageText = ({
  data: {
    primary: { image, image_caption, text },
  },
}) => {
  const src = image && image.responsive_large && image.responsive_large.url
  const medium_src =
    image && image.responsive_medium && image.responsive_medium.url
  const mobile_src =
    image && image.responsive_mobile && image.responsive_mobile.url

  return (
    <InnerContainer>
      <Container maxWidth={["100%", "100%", "100%", "88%", "80%"]}>
        <Box
          flex={1}
          width={["75%", "100%"]}
          maxWidth={["100%", "50%"]}
          pr={[0, 4]}
          mr={[0, 0]}
        >
          <Img
            src={src}
            dimensions={image.dimensions}
            medium_src={medium_src}
            mobile_src={mobile_src}
          />
          <Caption>{image_caption.text}</Caption>
        </Box>
        <Box
          flex={1}
          width={["100%", "100%"]}
          ml={[0, 4, 6, 7, 7]}
          mb={[0, 8]}
          px={[0, 4]}
          py={[0, 0]}
        >
          <P align="center">{text.text}</P>
        </Box>
      </Container>
    </InnerContainer>
  )
}

export default ProjectTwoUpImageText

export const imageQuery = graphql`
  fragment TwoUpImageText on PrismicProjectBody2UpImgTxt {
    id
    primary {
      image {
        responsive_large {
          url
        }
        responsive_mobile {
          url
        }
        responsive_medium {
          url
        }
        dimensions {
          width
          height
        }
      }
      image_caption {
        html
        text
      }
      text {
        html
        text
      }
    }
  }
`
