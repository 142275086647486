import React from "react"
import styled from "styled-components"

import { Box, theme } from "src/style"

const FakeImage = styled(Box)`
  background-image: url(${props => props.mobile_src});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;

  ${theme.mediaQueries.small} {
    background-image: url(${props => props.mobile_src});
  }

  ${theme.mediaQueries.medium} {
    background-image: url(${props => props.medium_src});
  }

  ${theme.mediaQueries.large} {
    background-image: url(${props => props.src});
  }
`

const Container = styled(Box)`
  background-color: transparent;
  position: relative;
  max-width: 100%;
`

const Image = ({
  dimensions: { width, height },
  src,
  medium_src,
  mobile_src,
}) => {
  const ratio = (height / width) * 100

  return (
    <Container pb={`${ratio}%`}>
      <FakeImage src={src} medium_src={medium_src} mobile_src={mobile_src} />
    </Container>
  )
}

export default Image
