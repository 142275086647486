import React from "react"
import { graphql } from "gatsby"

import { InnerContainer } from "src/templates/project"
import { Text, Box } from "src/style"

const ProjectHeader = ({
  data: {
    primary: { content },
  },
}) => {
  return (
    <InnerContainer>
      <Box justifyContent="center" alignItems="center" pt={7}>
        <Text fontSize="sm" align="center" fontFamily="boldSans">
          {content.text}
        </Text>
      </Box>
    </InnerContainer>
  )
}

export default ProjectHeader

export const headerQuery = graphql`
  fragment Header on PrismicProjectBodyHeader {
    id
    primary {
      content {
        html
        text
      }
    }
  }
`
