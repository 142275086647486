import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { useSpring } from "react-spring"
import styled from "styled-components"

import { Text, Box, theme } from "src/style"
import { InnerContainer } from "src/templates/project"

const Container = styled(Box).attrs({
  justifyConent: "center",
  alignItems: "center",
  pt: "14em",
  pb: "10em",
  mb: 0,
  mt: "2em",
})`
  position: relative;
  min-height: 10em;

  ${theme.mediaQueries.small} {
    margin-top: 10em;
    margin-bottom: 0.75em;
  }
`

const HeadlineBox = styled(Box)`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 93%;
  margin: 0 auto;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: translateZ(0);
  perspective: 1000;

  ${theme.mediaQueries.large} {
    max-width: 80%;
  }
`

const Headline = styled(Text).attrs({
  fontSize: ["xl", "7rem"],
  lineHeight: 1,
  fontFamily: "serif",
  align: "center",
})``

const ProjectHeadline = ({ project, theme }) => {
  const topStart = 50
  const topEnd = 0

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [setLoaded])

  const { top } = useSpring({
    top: loaded ? topEnd : topStart,
    config: { duration: 1000, delay: 100 },
  })

  const title =
    project.short_title && project.short_title.text
      ? project.short_title
      : project.title

  return (
    <InnerContainer>
      <Container>
        <HeadlineBox style={{ top }}>
          <Headline>{title.text}</Headline>
        </HeadlineBox>
      </Container>
    </InnerContainer>
  )
}

export default ProjectHeadline

export const headlineQuery = graphql`
  fragment Headline on PrismicProjectData {
    title {
      text
    }
    short_title {
      text
    }
  }
`
