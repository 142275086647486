import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Box, Caption, theme } from "src/style"
import Vimeo from "src/components/Vimeo"
import Img from "src/components/Image"
import { InnerContainer } from "src/templates/project"

const Container = styled(Box).attrs({
  display: "flex",
  alignItems: "center",
  flexDirection: ["column", "row"],
  pt: 4,
  pb: ["9em", 10, "7em", "8em", "9em"],
})`
  margin: 0 auto;
  justify-content: center;
`

const VideoContainer = styled(Box).attrs({
  flex: 1,
  maxWidth: ["100%", "50%"],
  pr: [0, 0],
  mt: [0, "-4em"],
  position: "relative",
})`
  ${props =>
    props.shadow === "YES" &&
    `
    box-shadow: 0px 0px 1.4em 0.2em #bdbdbd;
    border: 1px solid #e6e6e6;
  `}
  ${props =>
    props.vertical === "YES" &&
    `
    max-width: 40vw;
    
    ${theme.mediaQueries.small} {
      max-width: 25vw;
    }

    ${theme.mediaQueries.large} {
      max-width: 350px;
    }

    ${theme.mediaQueries.xlarge} {
      max-width: 350px;
    }
  `}
`

const ProjectTwoUpImageVideo = ({
  data: {
    primary: { video, image, image_caption, type, shadow, vertical },
  },
}) => {
  const src = image && image.responsive_large && image.responsive_large.url
  const medium_src =
    image && image.responsive_medium && image.responsive_medium.url
  const mobile_src =
    image && image.responsive_mobile && image.responsive_mobile.url

  return (
    <InnerContainer>
      <Container maxWidth={["100%", "100%", "100%", "88%", "80%"]}>
        <Box
          flex={1}
          mb={["5em", 0]}
          width={["75%", "100%"]}
          maxWidth={["100%", "50%"]}
          mr={[0, 4, 6, 7, 7]}
        >
          <Img
            src={src}
            dimensions={image.dimensions}
            medium_src={medium_src}
            mobile_src={mobile_src}
          />
          <Caption>{image_caption.text}</Caption>
        </Box>
        <VideoContainer
          width={["75%", "100%"]}
          ml={[0, 4, 6, 7, 7]}
          shadow={shadow}
          vertical={vertical}
        >
          <Vimeo video={video} loop={type === "LOOPED"} />
        </VideoContainer>
      </Container>
    </InnerContainer>
  )
}

export default ProjectTwoUpImageVideo

export const imageVideoQuery = graphql`
  fragment TwoUpImageVideo on PrismicProjectBody2UpImageVideo {
    primary {
      image {
        responsive_large {
          url
        }
        responsive_mobile {
          url
        }
        responsive_medium {
          url
        }
        dimensions {
          width
          height
        }
      }
      image_caption {
        html
        text
      }
      type: video_type
      shadow
      vertical
      video {
        uri
        is_plus
        width
        height
        embed_url
      }
    }
  }
`
