import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Vimeo from "src/components/Vimeo"
import { Box, P, theme } from "src/style"
import { InnerContainer } from "src/templates/project"

const Container = styled(Box).attrs({
  display: "flex",
  alignItems: "center",
  flexDirection: ["column", "row"],
  pt: 4,
  pb: [10, "12em", "7em", "8em", "9em"],
})`
  margin: 0 auto;
  justify-content: center;
`

const VideoContainer = styled(Box).attrs({
  flex: 1,
  maxWidth: ["100%", "50%"],
  pr: [0, 0],
  mr: [0, 0],
  position: "relative",
})`
  ${props =>
    props.shadow === "YES" &&
    `
    box-shadow: 0px 0px 1.4em 0.2em #bdbdbd;
    border: 1px solid #e6e6e6;
  `}
  ${props =>
    props.vertical === "YES" &&
    `
    max-width: 40vw;
    
    ${theme.mediaQueries.small} {
      max-width: 25vw;
    }

    ${theme.mediaQueries.large} {
      max-width: 350px;
    }

    ${theme.mediaQueries.xlarge} {
      max-width: 350px;
    }
  `}
`

const ProjectTwoUpTextVideo = ({
  data: {
    primary: { video, text, type, shadow, vertical },
  },
}) => {
  return (
    <InnerContainer>
      <Container maxWidth={["100%", "100%", "100%", "80%"]}>
        <Box flex={1} width={["100%", "100%"]} maxWidth={["100%", "50%"]} mr={[0, 4, 6, 7, 7]} mb={[0, 8]} px={[0, 4]} py={[7, 0]}>
          <P align="center">{text.text}</P>
        </Box>
        <VideoContainer width={["75%", "100%"]} shadow={shadow} vertical={vertical}>
          <Vimeo video={video} loop={type === "LOOP"} />
        </VideoContainer>
      </Container>
    </InnerContainer>
  )
}

export default ProjectTwoUpTextVideo

export const textVideoQuery = graphql`
  fragment TwoUpTextVideo on PrismicProjectBody2UpTextVideo {
    primary {
      text {
        text
      }
      type
      shadow
      vertical
      video: vimeo {
        uri
        is_plus
        width
        height
        embed_url
      }
    }
  }
`
