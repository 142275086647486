import React from "react"
import { Helmet } from "react-helmet"

const MetaTags = ({ image, description, urlPath }) => {
  const defaultDescription =
    "Founded in 2001 by Markus Kiersztan and Ben Sandler, MP Creative articulates and actualizes the messages of international luxury brands in an ever-changing world."
  const defaultImage =
    "https://images.prismic.io/mp-creative/8a6ebfde-4ed5-4818-925c-cee5a13de8ba_1200x675+MPCreative.jpg"

  return (
    <Helmet>
      <meta name="author" content="No Plans" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@MPCreativeNYC" />
      <meta
        property="og:url"
        content={`https://www.mpcreative.net/${urlPath}`}
      />
      <meta property="og:title" content="MP Creative" />
      <meta name="description" content={description || defaultDescription} />

      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta property="og:image" content={image || defaultImage} />
    </Helmet>
  )
}

export default MetaTags
