import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Box, Year, Text, A } from "src/style"
import { InnerContainer } from "src/templates/project"

const Container = styled(Box).attrs({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  pt: 10,
  pb: "2em",
})``

const ProjectRelatedProjects = ({ data: { items } }) => {
  return (
    <InnerContainer>
      <Container>
        <Text fontFamily="boldSans" fontSize="sm" mb={4}>
          Next
        </Text>

        {items.map(item => {
          const { related_project } = item
          const { uid, document } = related_project

          if (!document || document.length === 0) {
            return null
          }

          const {
            data: { label, title },
          } = document[0]

          return (
            <A to={`/work/${uid}`} key={uid}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                align="center"
              >
                <Year p={3}>{label && label.text}</Year>
                <Text fontFamily="serif" fontSize={["h1", "xl"]} align="center">
                  {title.text}
                </Text>
              </Box>
            </A>
          )
        })}
      </Container>
    </InnerContainer>
  )
}

export default ProjectRelatedProjects

export const projectsQuery = graphql`
  fragment RelatedProjects on PrismicProjectBodyRelatedProjects {
    items {
      related_project {
        uid
        document {
          data {
            label {
              text
            }
            title {
              html
              text
            }
          }
        }
      }
    }
  }
`
