import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Text, Box, theme } from "src/style"
import Image from "src/components/Image"
import { InnerContainer } from "src/templates/project"

const Container = styled(Box).attrs({
  justifyContent: "center",
  alignItems: "center",
  pt: 4,
  pb: ["5em", 10, "7em", "8em", "9em"],
})`
  margin: 0 auto;
  text-align: center;

  & * {
    background-color: transparent;
  }

  ${theme.mediaQueries.large} {
    max-width: 1100px;
  }

  ${theme.mediaQueries.xlarge} {
    max-width: 1500px;
  }

  ${props =>
    props.type === "50% (ALIGN LEFT)" &&
    `
    max-width: 75%;
    margin: 0 auto 0 auto;

    ${theme.mediaQueries.small} {
      max-width: 80%;
    }

    ${theme.mediaQueries.medium} {
      max-width: 50%;
      margin: 0 auto 0 0;
    }

    ${theme.mediaQueries.large} {
      max-width: 35%;
      margin-left: 10%;
    }
  `}

  ${props =>
    props.type === "50% (ALIGN RIGHT)" &&
    `
    max-width: 75%;
    margin: 0 auto 0 auto;

    ${theme.mediaQueries.small} {
      max-width: 80%;
    }

    ${theme.mediaQueries.medium} {
      max-width: 50%;
      margin: 0 0 0 auto;
    }

    ${theme.mediaQueries.large} {
      max-width: 35%;
      margin-right: 10%;
    }
  `}

  ${props =>
    props.type === "50% (ALIGN CENTER)" &&
    `
    max-width: 75%;
    margin: 0 auto;

    ${theme.mediaQueries.small} {
      max-width: 80%;
    }

    ${theme.mediaQueries.medium} {
      max-width: 50%;
    }

    ${theme.mediaQueries.large} {
      max-width: 35%;
    }
  `}

  ${props =>
    props.type === "FULL BLEED" &&
    `
    max-width: 100vw;
    margin: 0 auto;

    ${theme.mediaQueries.large} {
      max-width: 100vw;
    }

    ${theme.mediaQueries.xlarge} {
      max-width: 100vw;
    }
  `}
`

const Img = styled(Image)`
  margin: 0 auto;
`

const BoldCaption = styled(Text).attrs({
  fontFamily: "boldSans",
  fontSize: "sm",
  pt: "7",
})`
  max-width: 100%;
  text-align: center;
`

const ProjectImage = ({
  data: {
    primary: { image, caption, width },
  },
}) => {
  const src = image && image.responsive_large && image.responsive_large.url
  const medium_src =
    image && image.responsive_medium && image.responsive_medium.url
  const mobile_src =
    image && image.responsive_mobile && image.responsive_mobile.url

  return (
    <InnerContainer>
      <Container type={width}>
        <Img
          src={src}
          dimensions={image.dimensions}
          medium_src={medium_src}
          mobile_src={mobile_src}
        />
        <BoldCaption>{caption.text}</BoldCaption>
      </Container>
    </InnerContainer>
  )
}

export default ProjectImage

export const imageQuery = graphql`
  fragment Image on PrismicProjectBodyMediaWCaption {
    id
    primary {
      width
      image {
        dimensions {
          width
          height
        }
        responsive_large {
          url
        }
        responsive_mobile {
          url
        }
        responsive_medium {
          url
        }
      }
      caption {
        text
      }
    }
  }
`
