import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Box, Caption } from "src/style"
import Image from "src/components/Image"
import { InnerContainer } from "src/templates/project"

const Container = styled(Box).attrs({
  display: "flex",
  alignItems: "center",
  flexDirection: ["column", "row"],
  pt: 4,
  pb: ["7em", "10em", "7em", "8em", "9em"],
})`
  margin: 0 auto;
  justify-content: center;
`

const Img = styled(Image)`
  margin: 0 auto;
`

const ProjectTwoUpImageImage = ({
  data: {
    primary: {
      image_left,
      image_left_caption,
      image_right,
      image_right_caption,
    },
  },
}) => {
  const src_left =
    image_left && image_left.responsive_large && image_left.responsive_large.url
  const medium_src_left =
    image_left &&
    image_left.responsive_medium &&
    image_left.responsive_medium.url
  const mobile_src_left =
    image_left &&
    image_left.responsive_mobile &&
    image_left.responsive_mobile.url

  const src_right =
    image_right &&
    image_right.responsive_large &&
    image_right.responsive_large.url

  const medium_src_right =
    image_right &&
    image_right.responsive_medium &&
    image_right.responsive_medium.url
  const mobile_src_right =
    image_right &&
    image_right.responsive_mobile &&
    image_right.responsive_mobile.url

  return (
    <InnerContainer>
      <Container maxWidth={["100%", "100%", "100%", "80%"]}>
        <Box
          flex={1}
          mb={["4em", 0]}
          mr={[0, 4, 6, 7, 7]}
          align="center"
          width={["75%", "100%"]}
          maxWidth={["100%", "50%"]}
        >
          <Img
            src={src_left}
            dimensions={image_left.dimensions}
            medium_src={medium_src_left}
            mobile_src={mobile_src_left}
          />
          <Caption>{image_left_caption.text}</Caption>
        </Box>
        <Box
          flex={1}
          ml={[0, 4, 6, 7, 7]}
          align="center"
          width={["75%", "100%"]}
          maxWidth={["100%", "50%"]}
        >
          <Img
            src={src_right}
            dimensions={image_right.dimensions}
            medium_src={medium_src_right}
            mobile_src={mobile_src_right}
          />
          <Caption>{image_right_caption.text}</Caption>
        </Box>
      </Container>
    </InnerContainer>
  )
}

export default ProjectTwoUpImageImage

export const imageQuery = graphql`
  fragment TwoUpImageImage on PrismicProjectBody2UpImageImage {
    id
    primary {
      image_left {
        dimensions {
          width
          height
        }
        responsive_large {
          url
        }
        responsive_mobile {
          url
        }
        responsive_medium {
          url
        }
      }
      image_right {
        dimensions {
          width
          height
        }
        responsive_large {
          url
        }
        responsive_mobile {
          url
        }
        responsive_medium {
          url
        }
      }
      image_left_caption {
        html
        text
      }
      image_right_caption {
        html
        text
      }
    }
  }
`
