import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Vimeo from "src/components/Vimeo"
import { Text, Box, theme } from "src/style"
import { InnerContainer } from "src/templates/project"

const Container = styled(Box).attrs({
  justifyContent: "center",
  alignItems: "center",
  mx: "auto",
  mb: ["9em", "12em", "11em", "12em", "13em"],
})`
  position: relative;
  width: 100%;
  border: 0;

  ${theme.mediaQueries.large} {
    max-width: 1300px;
  }

  ${theme.mediaQueries.xlarge} {
    max-width: 1500px;
  }

  ${props =>
    props.type === "SMALL LEFT" &&
    `
    max-width: 600px;
    
    ${theme.mediaQueries.large} {
      max-width: 700px;
      margin-left: calc((100vw - 80%)/2);
    }

    ${theme.mediaQueries.xlarge} {
      max-width: 800px;
      margin-left: calc((100vw - 80%)/2);
    }
  `}

  ${props =>
    props.type === "SMALL RIGHT" &&
    `
    max-width: 600px;

    ${theme.mediaQueries.large} {
      max-width: 700px;
      margin-right: calc((100vw - 80%)/2);
    }

    ${theme.mediaQueries.xlarge} {
      max-width: 800px;
      margin-right: calc((100vw - 80%)/2);
    }
  `}
  ${props =>
    props.type === "SMALL CENTER" &&
    `
    max-width: 600px;
    margin-left: auto;

    ${theme.mediaQueries.large} {
      max-width: 700px;
    }

    ${theme.mediaQueries.xlarge} {
      max-width: 800px;
    }
  `}
  ${props =>
    props.type === "FULL BLEED" &&
    `
    max-width: 100vw;
    margin-left: auto;

    ${theme.mediaQueries.large} {
      max-width: 100vw;
    }
  `}
  ${props =>
    props.shadow === "YES" &&
    `
    box-shadow: 0px 0px 1.5em 0.1em #d0d0d0;
    border: 1px solid #e6e6e6;
  `}
  ${props =>
    props.vertical === "YES" &&
    `
    max-width: 20vw;
    
    ${theme.mediaQueries.small} {
      max-width: 20vw;
    }

    ${theme.mediaQueries.large} {
      max-width: 350px;
    }

    ${theme.mediaQueries.xlarge} {
      max-width: 350px;
    }
  `}
`

const CaptionContainer = styled(Box)`
  position: absolute;
  top: calc(100% + 0.1em);
  width: 100%;
  min-height: 4em;
  border: 0;
  max-width: 100%;
  text-align: center;
`

const BoldCaption = styled(Text).attrs({
  fontFamily: "boldSans",
  fontSize: "sm",
  pt: "7",
})`
  max-width: 100%;
  text-align: center;
`

const ProjectVideo = ({
  data: {
    primary: { video, caption, width, type, shadow, vertical },
  },
}) => {
  return (
    <InnerContainer>
      <Container type={width} shadow={shadow} vertical={vertical}>
        <Vimeo video={video} loop={type === "LOOPED"} />
        <CaptionContainer>
          <BoldCaption>{caption.text}</BoldCaption>
        </CaptionContainer>
      </Container>
    </InnerContainer>
  )
}

export default ProjectVideo

export const videoQuery = graphql`
  fragment Video on PrismicProjectBodyVideoWCaption {
    id
    primary {
      width
      type
      shadow
      vertical
      caption {
        text
      }
      video: vimeo {
        uri
        is_plus
        width
        height
        embed_url
      }
    }
  }
`
