import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Box, theme } from "src/style"
import { InnerContainer } from "src/templates/project"
import Vimeo from "src/components/Vimeo"

const Container = styled(Box).attrs({
  display: "flex",
  alignItems: "center",
  flexDirection: ["column", "row"],
  pt: 4,
  pb: ["7em", "9em", "8em", "9em"],
})`
  margin: 0 auto;
  justify-content: center;
`

const VideoContainer = styled(Box).attrs({
  flex: 1,
  maxWidth: ["100%", "50%"],
  position: "relative",
})`

  ${props =>
    props.shadow === "YES" &&
    `
    box-shadow: 0px 0px 1.5em 0.1em #d0d0d0;
    border: 1px solid #e6e6e6;
  `}
  ${props =>
    props.vertical === "YES" &&
    `
    max-width: 40vw;
    
    ${theme.mediaQueries.small} {
      max-width: 25vw;
    }

    ${theme.mediaQueries.large} {
      max-width: 350px;
    }

    ${theme.mediaQueries.xlarge} {
      max-width: 350px;
    }
  `}
`

const ProjectTwoUpVideoVideo = ({
  data: {
    primary: { video__left, video__right, type__right, type__left, shadow__left, shadow__right, vertical__left, vertical__right },
  },
}) => {
  return (
    <InnerContainer>
      <Container maxWidth={["100%", "100%", "100%", "80%"]}>
        <VideoContainer width={["75%", "100%"]} mb={["9em", 9]} mr={[0, 4, 6, 7, 7]} shadow={shadow__left} vertical={vertical__left}>
          <Vimeo video={video__left} loop={type__left === "LOOP"} />
        </VideoContainer>
        <VideoContainer width={["75%", "100%"]} mb={[0, 9]} ml={[0, 4, 6, 7, 7]} shadow={shadow__right} vertical={vertical__right}>
          <Vimeo video={video__right} loop={type__right === "LOOP"} />
        </VideoContainer>
      </Container>
    </InnerContainer>
  )
}

export default ProjectTwoUpVideoVideo

export const videoVideoQuery = graphql`
  fragment TwoUpVideoVideo on PrismicProjectBody2UpVideoVideo {
    primary {
      type__left
      type__right
      shadow__left
      shadow__right
      vertical__left
      vertical__right
      video__left: vimeo__left {
        uri
        is_plus
        width
        height
        embed_url
      }
      video__right: vimeo__right {
        uri
        is_plus
        width
        height
        embed_url
      }
    }
  }
`
