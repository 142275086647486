const preload = url =>
  new Promise((resolve, reject) => {
    if (typeof window !== "undefined") {
      const img = new Image()
      img.src = url
      img.onload = () => resolve(url)
      img.onerror = reject
    } else {
      resolve(url)
    }
  })

const preloadImages = (urls = []) => Promise.all(urls.map(preload))

export default preloadImages
