import React, { useState } from "react"
import { graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import { ThemeProvider } from "styled-components"
import { Waypoint } from "react-waypoint"

import { lightTheme, darkTheme, Style } from "src/style"
import Menu from "src/components/Menu"

import MetaTitle from "src/components/MetaTitle"
import MetaTags from "src/components/MetaTags"
import { Box, theme } from "src/style"

import ProjectHeadline from "src/components/ProjectHeadline"
import ProjectHeader from "src/components/ProjectHeader"
import ProjectParagraph from "src/components/ProjectParagraph"
import ProjectImage from "src/components/ProjectImage"
import ProjectImagePlusText from "src/components/ProjectImagePlusText"
import ProjectTextPlusImage from "src/components/ProjectTextPlusImage"
import ProjectImagePlusImage from "src/components/ProjectImagePlusImage"
import ProjectRelatedProjects from "src/components/ProjectRelatedProjects"
import ProjectSlideshow from "src/components/ProjectSlideshow"
import ProjectVideo from "src/components/ProjectVideo"
import ProjectVideoPlusText from "src/components/ProjectVideoPlusText"
import ProjectVideoPlusVideo from "src/components/ProjectVideoPlusVideo"
import ProjectTextPlusVideo from "src/components/ProjectTextPlusVideo"
import ProjectImagePlusVideo from "src/components/ProjectImagePlusVideo"
import ProjectVideoPlusImage from "src/components/ProjectVideoPlusImage"

const Container = styled(Box).attrs({
  justifyContent: "center",
  alignItems: "center",
})``

export const InnerContainer = styled(Box).attrs({
  maxWidth: "90%",
})`
  margin: 0 auto;
  margin-left: 5%;

  ${theme.mediaQueries.medium} {
    width: 80%
    margin-left: 10%;
  }

  ${theme.mediaQueries.large} {
    width: 100%;
    margin: 0 auto;
    max-width: none;
  }

  ${theme.mediaQueries.xlarge} {
    max-width: none;
  }
`

const FlickityStyle = createGlobalStyle`
  .is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled {
    width: 100vw;
    height: 40vh;
    max-height: 1100px;
    margin-bottom: 7em;
    display: flex;
    position: relative;
    outline: none;
    overflow: hidden;
    position: relative;

    ${theme.mediaQueries.small} {
      height: 50vh;
      margin-bottom: 12em;
    }

    ${theme.mediaQueries.medium} {
      height: 84vh;
      margin-bottom: 9em;
    }

    ${theme.mediaQueries.large} {
      margin-bottom: 10em;
    }

    ${theme.mediaQueries.xlarge} {
      margin-bottom: 11em;
    }
  }

  .flickity-enabled:focus {
    outline: none;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  /* ---- flickity-button ---- */

  .flickity-button {
    position: absolute;
    background: transparent;
    border: none;
    color: #333;
  }

  .flickity-button:hover {
    background: white;
    cursor: pointer;
    background: transparent;
  }

  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
  }

  .flickity-button:active {
    opacity: 0.6;
  }

  .flickity-button:disabled {
    opacity: 0;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }

  .flickity-button-icon {
    fill: #fff !important;
  }

  /* --- previous/next buttons --- */

  .flickity-prev-next-button {
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);

    ${theme.mediaQueries.small} {
      width: 38px;
      height: 38px;
    }
  }

  .flickity-prev-next-button.previous {
    left: 10px;
  }
  .flickity-prev-next-button.next {
    right: 10px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }

  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
`

const getBodyComponent = type =>
  ({
    PrismicProjectBodyHeader: ProjectHeader,
    PrismicProjectBodyText: ProjectParagraph,
    PrismicProjectBodyMediaWCaption: ProjectImage,
    PrismicProjectBody2UpImgTxt: ProjectImagePlusText,
    PrismicProjectBody2Up: ProjectTextPlusImage,
    PrismicProjectBody2UpImageImage: ProjectImagePlusImage,
    PrismicProjectBodyRelatedProjects: ProjectRelatedProjects,
    PrismicProjectBodySlideshow: ProjectSlideshow,
    PrismicProjectBodyVideoWCaption: ProjectVideo,
    PrismicProjectBody2UpTextVideo: ProjectTextPlusVideo,
    PrismicProjectBody2UpVideoText: ProjectVideoPlusText,
    PrismicProjectBody2UpVideoVideo: ProjectVideoPlusVideo,
    PrismicProjectBody2UpVideoImage: ProjectVideoPlusImage,
    PrismicProjectBody2UpImageVideo: ProjectImagePlusVideo,
  }[type])

const PAGE_SIZE = 10

const Project = ({ data }) => {
  const {
    project: { data: project },
  } = data

  const title = project.title.text

  const [totalLoaded, setTotalLoaded] = useState(PAGE_SIZE)
  const paginatedComponents = project.body
    ? project.body.slice(0, totalLoaded)
    : []

  const { background_color, navigation_color } = project
  const defaultTheme = navigation_color === "WHITE" ? darkTheme : lightTheme
  const defaultBg = navigation_color === "LIGHT" ? "#000" : "#F2F2F2"
  const bgColor = background_color ? background_color : defaultBg

  const theme = bgColor
    ? {
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          background: bgColor,
        },
      }
    : defaultTheme

  return (
    <ThemeProvider theme={theme}>
      <>
        <Style />
        <FlickityStyle />
        <MetaTitle title={title} />
        <MetaTags urlPath={`work/${data.project.uid}`} />
        <Menu title={title} />
        <ProjectHeadline project={project} />
        <Container bgColor={bgColor}>
          {paginatedComponents.map(data => {
            const Component = getBodyComponent(data.__typename)

            if (!Component) return null

            try {
              return <Component key={data.id} data={data} />
            } catch (e) {
              return console.error(`Error mounting component${e}`)
            }
          })}
          {totalLoaded < project.body.length && (
            <Waypoint
              bottomOffset="-800px"
              onEnter={() => setTotalLoaded(totalLoaded + PAGE_SIZE)}
            />
          )}
        </Container>
      </>
    </ThemeProvider>
  )
}

export default Project

export const pageQuery = graphql`
  query ProjectQuery($uid: String) {
    project: prismicProject(uid: { eq: $uid }) {
      id
      uid
      type
      slugs
      data {
        ...Headline
        navigation_color
        background_color
        body {
          __typename
          ...Header
          ...Paragraph
          ...Image
          ...TwoUpImageText
          ...TwoUpTextImage
          ...TwoUpImageImage
          ...RelatedProjects
          ...Slideshow
          ...Video
          ...TwoUpVideoVideo
          ...TwoUpVideoText
          ...TwoUpTextVideo
          ...TwoUpImageVideo
          ...TwoUpVideoImage
        }
      }
    }
  }
`
