import React, { useState } from "react"
import styled from "styled-components"
import VimeoPlayer from "react-player/lib/players/Vimeo"

import Icon from "src/components/Icon"
import { Box, theme } from "src/style"

const Player = styled(VimeoPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  perspective: 1000;
  transform-style: preserve-3d;

  & > div {
    background: transparent !important;
  }
`

const Play = styled(Icon).attrs(props => ({
  name: props.playing ? "Pause" : "Play",
  color: "logo",
  width: "2.6em",
  height: "2.6em",
}))`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s;
  transform: scale(1);

  ${theme.mediaQueries.small} {
    width: 3em;
    height: 3em;
  }

  ${theme.mediaQueries.medium} {
    padding: 10em;

    svg {
      width: 15%;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s;
    opacity: 1;
  }

  ${props =>
    !props.hovered && props.playing &&
    `
    opacity: 0;
  `}
  
  ${props =>
    props.hovered && props.playing &&
    `
    opacity: 1;

    ${theme.mediaQueries.medium} { 
      opacity: 0;
    }
    
  `}

`

const VimeoContainer = styled(Box)`
  position: relative;
  cursor: pointer;

  ${props =>
    props.portrait &&
    `
    max-height: 80vh;

  `}
`

const Vimeo = ({ video, loop = false }) => {
  const { height, width } = video
  const ratio = (height / width) * 100
  const [playing, setPlaying] = useState(loop)
  const [hovered, setHovered] = useState(false)

  return (
    <VimeoContainer
      pb={`${ratio}%`}
      playing={playing}
      portrait={height > width}
    >
      <Player
        url={video.embed_url}
        controls={false}
        width="100%"
        height="100%"
        light
        muted={loop}
        loop={loop}
        playing={playing}
        autoplay={loop}
        style={{ background: "transparent" }}
        config={{
          vimeo: {
            playerOptions: {
              controls: false,
              autopause: false,
              playsinline: true,
            },
          },
        }}
        onEnded={() => setPlaying(false)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      />
      {!loop && (
        <Play
          onClick={() => setPlaying(!playing)}
          playing={playing}
          hovered={hovered}
        />
      )}
    </VimeoContainer>
  )
}

export default Vimeo
