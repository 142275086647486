import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import preloadImages from "src/utils/preloadImages"

const Flickity =
  typeof window !== "undefined"
    ? require("react-flickity-component")
    : () => <div />

const Img = styled.img`
  height: 100%;
  width: auto;
  margin-right: 8em;
`

const ProjectSlideshow = ({ data: { items } }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false)

  const urls = items.map(
    ({ image }) => image && image.responsive_large && image.responsive_large.url
  )
  preloadImages(urls).then(() => setImagesLoaded(true))

  if (typeof window !== "undefined" && imagesLoaded) {
    return (
      <div>
        <Flickity
          reloadOnUpdate
          static
          className="carousel"
          elementType="div"
          options={{
            freeScroll: false,
            wrapAround: true,
            pageDots: false,
            imagesLoaded: true,
            prevNextButtons: true,
            autoPlay: 3400,
            pauseAutoPlayOnHover: false,
            contain: true,
            arrowShape: "M 10,50 L 58,98 L 65,90 L 25,50 L 65,10 L 58,2 Z",
            selectedAttraction: 0.055,
            friction: 1.2,
            percentPosition: false,
          }}
        >
          {items.map(({ image }) => {
            const src =
              image && image.responsive_large && image.responsive_large.url

            return <Img src={src} />
          })}
        </Flickity>
      </div>
    )
  }
  return null
}

export default ProjectSlideshow

export const slideshowQuery = graphql`
  fragment Slideshow on PrismicProjectBodySlideshow {
    id
    items {
      image {
        responsive_large {
          url
        }
        responsive_mobile {
          url
        }
        responsive_medium {
          url
        }
      }
    }
  }
`
